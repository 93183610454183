<template>
  <div class="m-header">
    <div class="m-header-content" ref="hbox">
      <div class="header-right">
        <i
          :class="[
            'icon-nav',
            m_nav_show ? 'el-icon-s-unfold' : 'el-icon-s-fold',
          ]"
          @click="toggleNav"
        ></i>
      </div>
      <p>
        <img src="@assets/imgs/logowhite.png" @click="gotohome" />
      </p>
      <div class="selectbox">
        <div class="select">
          <el-select
            size="mini"
            :value="lang"
            @change="onChangeLang"
            style="width: 55px"
            v-if="!isCN"
          >
            <el-option
              v-for="item in lang_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="padding: 0 10px;"
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                
                <img
                  :src="
                    require('@assets/imgs/nationalflag/' + item.img + '.svg')
                  "
                  style="width: 20px"
                />
                <span >{{ item.label }}</span>
              </div>

            </el-option>
            <template slot="prefix">
              <span style="height: 100%;display: flex;align-items: center;">
                <img
                v-if="lang"
                :src="require('@assets/imgs/nationalflag/' + lang_options.find(option => option.value === lang).img+ '.svg')"
                style="width: 25px;"
                />
              </span>
                        
            </template>
          </el-select>
          <i
            class="el-icon-user"
            @click="() => onNavClick(4)"
            v-if="isLogin"
          ></i>
          <span class="showlogin" @click="onLogin" v-if="!isLogin">
            {{ $t("index.登录") }}
          </span>
        </div>
      </div>
    </div>
    <div :class="['nav', { show: m_nav_show }]">
      <div :class="['nav-item', { active: spreadNavIdx === 0 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(0)">
          <span>{{ $t("index.产品") }}</span>
          <i id="add"></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '0_1' }]"
            @click.stop="() => onNavClick(0, 1)"
          >
            ORI
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '0_2' }]"
            @click="onNavClick(0, 2)"
          >
            Alpha
          </div>
        </div>
      </div>
      <!-- <div :class="['nav-item', 'item-style', { active: spreadNavIdx === 1 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(1)">
          <span> {{ $t("index.生活方式") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '1_1' }]"
            @click="onNavClick(1, 1)"
          >
            {{ $t("lifeStyle.摩友圈") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '1_2' }]"
            @click="onNavClick(1, 2)"
          >
            {{ $t("lifeStyle.俱乐部") }}
          </div>
        </div>
      </div> -->
      <div class="nav-item" >
        <div class="nav-item-content" @click="onNavClick(1)">
          <span> {{ $t("index.vr看车") }}</span>
          <i></i>
        </div>
      </div>
      <div class="nav-item" v-if="isCN">
        <div class="nav-item-content" @click="onNavClick(5, 0)">
          <span>{{ $t("index.好物") }}</span>
          <i></i>
        </div>
      </div>
      <div class="nav-item" v-if="isCN">
        <div class="nav-item-content" @click="gopartner">
          <span> {{ $t("index.合作伙伴申请") }}</span>
          <i></i>
        </div>
      </div>

      <div :class="['nav-item', { active: spreadNavIdx === 3 }]" >
        <div class="nav-item-content" @click="setSpreadNavIdx(3)">
          <span>{{ $t("index.关于我们") }}</span>
          <i id="add"></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '3_1' }]"
            @click="onNavClick(3, 1)"
          >
            {{ $t("index.品牌理念") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '3_2' }]"
            @click="onNavClick(3, 2)"
          >
            {{ $t("index.我们的故事") }}
          </div>

        </div>
      </div>
      <div class="nav-item">
        <div class="nav-item-content" @click="onNavClick(7)">
          <span> {{ $t("index.新闻资讯") }}</span>
          <i></i>
        </div>
      </div>
      
      <div :class="['nav-item', { active: spreadNavIdx === 2 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(2)">
          <span>{{ $t("index.服务与支持") }}</span>
          <i id="add"></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '2_1' }]"
            @click.stop="() => onNavClick(2, 1)"
          >
            {{ $t("index.下载") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '2_2' }]"
            @click.stop="() => onNavClick(2, 2)"
          >
            {{ $t("index.品牌课堂") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '2_3' }]"
            @click.stop="() => onNavClick(2, 3)"
          >
            {{ $t("index.产品说明书") }}
          </div>
          <div
            v-if="!isCN"
            :class="['sub-nav-item', { active: curNav === '2_4' }]"
            @click.stop="() => onNavClick(2, 4)"
          >
            {{ $t("index.常见问题") }}
          </div>
        </div>
      </div>
      <div class="nav-item" v-if="!isCN">
        <div class="nav-item-content" @click="onNavClick(10)">
          <span> {{ $t("index.找到我们") }}</span>
          <i></i>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 9 }]" v-if="!isCN">
        <div class="nav-item-content" @click="setSpreadNavIdx(9)">
          <span>{{ $t("index.联系我们") }}</span>
          <i id="add"></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '9_1' }]"
            @click.stop="() => onNavClick(9, 1)"
          >
            {{ $t("index.合作伙伴申请") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '9_2' }]"
            @click.stop="() => onNavClick(9, 2)"
          >
            {{ $t("index.预约试驾") }}
          </div>
          <div
            v-if="false"
            :class="['sub-nav-item', { active: curNav === '9_3' }]"
            @click.stop="() => onNavClick(9, 3)"
          >
            {{ $t("index.故障排除") }}
          </div>
        </div>
      </div>
      <!-- <div class="nav-bottom">
        <div class="nav-item nav-item-login" v-if="!isLogin" @click="onLogin">
          {{ $t("index.登录") }}
        </div>
         <div
              v-if="isLogin"
              :class="['user-item', 'nav-item', { active: curNav1 === '4' }]"
              @click="() => onNavClick(4)"
            >
              <i class="el-icon-user"></i>
            </div>
      </div> -->
    </div>
    <FloatBall />
    <CookieBanner v-if="!isCN"/>
  </div>
  
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { goto } from "@utils/goto";
import RouteMap, { activeRoute } from "./RouteMap";
import FloatBall from "../../pages/index/components/FloatBall.vue";
import CookieBanner from "../CookiesSetting/CookieBanner.vue";
export default {
  watch: {
    m_nav_show(showNav) {
      if (showNav) {
        document.body.style.cssText = `height:100vh;overflow:hidden`;
      } else {
        document.body.style.cssText = "";
      }
    },
    lang: function () {
      if (!this.isCN) {
        this.nowlang = localStorage.getItem(this.currentUrl);
        this.setLang(this.nowlang);

        if(!this.nowlang){
          document.documentElement.lang = "en-US";
        }
        else{
          document.documentElement.lang = this.nowlang;
        }
      }
      
    },
  },
  components: { FloatBall, CookieBanner},
  computed: {
    ...mapState(["lang"]),
    ...mapGetters(["isLogin"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      curNav: activeRoute,
      m_nav_show: false,
      spreadNavIdx: 10,
      color: "white",
      nowlang: "",
      currentUrl: "",
      lang_options: [
        { value: "en-US", label: "US", img: "United_States" },
        { value: "en-GB", label: "UK", img: "United_Kingdom" },
        { value: "ru-RU", label: "RU", img: "Russia" },
        { value: "fr-FR", label: "FR", img: "France" },
        { value: "es-ES", label: "ES", img: "Spain" },
        { value: "ja-JP", label: "JP", img: "Japan" },
      ],
    };
  },
  mounted() {
    this.currentUrl = window.location.hostname + "nowlang";
    if (!this.isCN) {
      //每进入一个页面先获取当前存在localStorage的语言字段nowlang
      this.nowlang = localStorage.getItem(this.currentUrl);
      //url参数改变语言存入localStorage
      const langid = window.location.href.split("lang=")[1];
      if (langid) {
        localStorage.setItem(this.currentUrl, langid);
        this.nowlang = langid;
        this.setLang(this.nowlang);
        // console.log(this.nowlang);
      }
      setTimeout(() => {
        this.setLang(this.nowlang);
      }, 300);
    } else {
      localStorage.setItem(this.currentUrl, "zh-CN");
    }
  },
  methods: {
    ...mapActions(["setLang"]),
    setSpreadNavIdx(idx) {
      //  2暂时没有菜单
      // if ([2].includes(idx)) return;
      if (idx === this.spreadNavIdx) {
        this.spreadNavIdx = "";
      } else {
        this.spreadNavIdx = idx;
      }
    },
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      if (url) {
        goto(url);
      }
    },
    onChangeLang(lang) {
      //手动选择器改变语言存入localStorage
      localStorage.setItem(this.currentUrl, lang);
      this.setLang(lang);
    },
    toggleNav() {
      this.m_nav_show = !this.m_nav_show;
      if (this.m_nav_show) {
        this.$refs.hbox.style.color = "black";
        this.$refs.hbox.style.background = "white";
        this.$refs.hbox.style.borderBottom = "1px solid #000000";
      } else {
        this.$refs.hbox.style.color = this.color;
        this.$refs.hbox.style.background = "rgba(0,0,0,0)";
        this.$refs.hbox.style.borderBottom = "none";
      }
    },
    onLogin() {
      goto("/login");
    },
    gotohome() {
      goto("/");
    },
    gopartner() {
      goto("/partner");
    },
    dcolor() {
      // this.$refs.hbox.style.color = "black";
      // this.$refs.hbox.style.background = "white";
    },
    handleScrollx() {
      // console.log("滚动高度", window.pageYOffset);
      if (window.pageYOffset > 10) {
        this.$refs.hbox.style.background = "rgba(0,0,0,0.7)";
      } else {
        this.$refs.hbox.style.background = "none";
      }
    },
    // onTrialApplication() {
    //   goto("/trial");
    // },
  },

  //离开页面时一定要销毁不然会出问题
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollx);
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.m-header {
  position: fixed;
  z-index: 100;
  top: 0;
  overflow: visible;
  width: 100%;
  .m-header-content {
    position: relative;
    z-index: 1000;
    @extend .flex-between;
    padding: 0 px2rem(10px);
    height: px2rem(30px);
    background-color: rgba($color: #000000, $alpha: 0.2);
    color: #ffffff;
    font-size: px2rem(22px);
    p {
      
      // width: fill-available;
      text-align: center;
      img {
        width: px2rem(80px);
      }
    }
    .selectbox {
      position: relative;
      width: px2rem(23px);
      height: 100%;
    }
    .select {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      height: 100%;
      // top: px2rem(-12px);
    }
    .select ::v-deep {
      .el-select {
        margin: 0 px2rem(2px);
      }
      .el-input__inner {
        background-color: transparent;
        color: #ffffff;
        padding: 0 0 0 px2rem(2px);
        margin: 0;
        font-size: 0;
        border: 0;
      }
      .el-input__suffix {
        i {
          color: #ffffff;
        }
      }
    }
    .showlogin {
      font-size: px2rem(14px);
      width: max-content;
    }
    .header-right {
      // flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon-nav {
        font-size: px2rem(22px);
      }
    }
  }
  #add {
    background: url(~@assets/imgs/icon_add.png) no-repeat center;
  }
  .nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 100%);
    padding: px2rem(10px) px2rem(15px) px2rem(20px);
    z-index: 999;
    // background: #ffffff;
    display: flex;
    flex-direction: column;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s;
    &.show {
      transform: translate3d(0, 0, 0);
      background-color: white;
    }
    .nav-item {
      margin-top: px2rem(7px);
      line-height: px2rem(70px);
      // 展开
      &.active {
        #add {
          background-image: url(~@assets/imgs/icon_remove.png);
        }
        .sub-nav {
          display: block;
        }
      }
      .nav-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: px2rem(17px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        line-height: px2rem(40px);
        height: px2rem(50px);
        // padding-bottom: px2rem(3px);
        border-bottom: 1px solid #000000;
        i {
          width: px2rem(25px);
          height: px2rem(25px);
          background-size: 60%;
          background: url(~@assets/imgs/icon_right.png) no-repeat center;
          color: #000;
        }
      }
      .sub-nav {
        display: none;
        padding-top: px2rem(11px);
        padding-bottom: px2rem(11px);
        font-size: px2rem(16px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: px2rem(25px);
        .sub-nav-item {
          &.active {
            color: #e8e354;
          }
          &:nth-child(n + 2) {
            margin-top: px2rem(8px);
          }
        }
      }
    }
    .nav-bottom {
      position: absolute;
      bottom: px2rem(37px);
      left: px2rem(15px);
      right: px2rem(15px);
      padding: px2rem(12px) px2rem(12px) 0;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      border-top: 1px solid #000;

      .nav-item-login {
        margin-top: px2rem(37px);
        font-size: px2rem(17px);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: px2rem(24px);
      }
    }
  }
}
</style>
