<template>
  <div class="wrap">
    <div class="box">
      <div><img src="../../../assets/imgs/starticon.png" /></div>
      <div class="title">GOWOW App</div>
      <div class="content">
        <p>{{ $t("slides.下载GOWOW App") }}</p>
      </div>
      <a
        class="apple"
        href="itms-apps://itunes.apple.com/app/id6443576078"
        target="_blank"
      >
        <img src="../../../assets/imgs/iphone.png" />{{
          $t("slides.AppStore下载")
        }}
      </a>
      <a class="apple" :href="detail.url" target="_blank">
        <img src="../../../assets/imgs/android.png" />
        {{ $t("slides.Android下载") }}
      </a>
    </div>
    <div class="bgimg">
      <img
        :src="'https://ai.modehoist.com/gowow/website/app/' + name + '-CN.png'"
        v-if="isCN"
      />
      <img
        :src="'https://ai.modehoist.com/gowow/website/app/' + name + '-CN.png'"
        v-else
      />
    </div>
  </div>
</template>
  
<script>
import { app_update } from "@service/api";
import { mapState } from "vuex";

export default {
  data() {
    return {
      detail: {},
      name: "",
    };
  },
  computed: {
    ...mapState(["lang", "profile"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  mounted() {
    this.fetchDetail();
    this.name = window.location.pathname.split("/").pop();
    if (this.name == "download") {
      this.name = "ORI";
    }
  },
  methods: {
    async fetchDetail() {
      try {
        const data = await app_update({
          language: "zh-cn",
          product: "0",
          type: "0",
        });
        this.detail = data[0];
        // console.log(data[0]);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

   
      
 <style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
* {
  padding: 0;
  margin: 0;
}
img {
  vertical-align: top;
}
.wrap {
  position: relative;
}
.box {
  width: 50vw;
  text-align: center;
  padding: px2rem(10px) 0;
  position: absolute;
  top: 10%;
  left: 45%;
  img {
    width: px2rem(50px);
  }
  .title {
    font-family: PingFang-SC-Heavy;
    font-weight: 600;
    font-size: px2rem(28px);
    line-height: px2rem(40px);
  }
  .content {
    font-family: PingFang-SC-Medium;
    font-size: px2rem(14px);
    margin: px2rem(10px) 0;
    line-height: px2rem(20px);
  }
  .apple {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #fff;
    height: px2rem(40px);
    width: px2rem(185px);
    margin-top: px2rem(7px);
    border: black 1px solid;
    border-radius: px2rem(5px);
    font-family: PingFang-SC-Bold;
    font-weight: 600;
    font-size: px2rem(14px);
    color: black;
    img {
      width: px2rem(30px);
      padding-right: (5px);
    }
  }
}
.bgimg {
  background-color: rgb(244, 244, 244);

  img {
    width: 100%;
  }
}
</style>