<template>
  <div class="wrap">
    <div class="back"><img src="@assets/imgs/partnerM/3.png" /></div>
    <div class="box">
      <p class="title">ORI {{ $t("index.预约试驾") }}</p>
      <van-form
        class="textbox"
        ref="form"
        @submit="onSubmit"
        label-width="auto"
        colon
        :show-error="false"
      >
        <div>
          <van-field
            class="text"
            v-model="address"
            name="address"
            :label="$t('index.体验地点')"
            required
            :rules="[{ required: true, message: $t('index.请填写体验地点') }]"
          />
        </div>
        <div class="phonebox">
          <!-- <i class="el-icon-plus" v-if="!isCN"></i> -->
          <van-field
            v-if="!isCN"
            v-model="countrycode"
            class="text countrycode"
            type="tel"
            name="countrycode"
            :label="$t('register.请输入国家编码')"
            required
            :rules="[
              { required: true, message: $t('register.请输入国家编码') },
            ]"
          >
            <i class="el-icon-plus" slot="button" v-if="!isCN"></i
          ></van-field>
        </div>
        <div>
          <van-field
            class="text"
            v-model="phone"
            type="tel"
            name="phone"
            :label="$t('index.手机号')"
            required
            :rules="[
              { required: true, message: $t('index.请填写手机号') },
              { message: $t('index.格式不正确'), validator: validatorTel },
            ]"
          />
        </div>

        <div class="verifybox">
          <van-field
            class="verifyinput"
            v-model="verify"
            name="verify"
            :label="$t('register.验证码')"
            required
            :rules="[{ required: true, message: $t('register.请输入验证码') }]"
          >
            <van-button
              block
              class="verifybtn"
              type="info"
              @click="onCodeBtnClick"
              :disabled="disabled"
              slot="button"
            >
              {{ disabled ? `${count}s` : $t("register.获取验证码") }}
            </van-button></van-field
          >
        </div>
        <div>
          <van-checkbox
            v-if="!isCN"
            v-model="consent" 
            class="checkbox"
            name="consent"
            :label="$t('register.验证码')"
            required
            >
            I have read and agree to the
            <a href="/privacy" target="_blank" style="color: brown; text-decoration: underline;">Privacy Policy</a>
            and
            <a href="/terms" target="_blank" style="color: brown; text-decoration: underline;">Terms of service.</a>
            
          </van-checkbox>
        </div>
        <div class="submit-btn-wrap">
          <van-button block type="info" native-type="submit" color="black">
            {{ $t("index.提交") }}
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Message from "@utils/Message";
import { feedbackAdd2, verificationCode } from "@service/api";
// import { log } from "console";

export default {
  data() {
    return {
      // 表单业务数据
      phone: "",
      verify: "",
      address: "",
      countrycode: "",
      consent: false,
      disabled: false,
      count: 60,
      timeRef: null,
    };
  },
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  async mounted() {},
  watch: {},
  beforeDestroy() {
    this.timeRef && clearInterval(this.timeRef);
  },
  methods: {
    onCodeBtnClick() {
      if (!this.disabled) {
        const filed = "phonenumber";
        const value = this.phone;
        // console.log(value);
        if (!value) {
          const msg = "请输入电话";
          Message.fail(this.$t(`register.${msg}`));
          return;
        }
        const params = { [filed]: value };
        if (!this.isCN) {
          params.phonenumber = "+" + this.countrycode + this.phone;
        }
        console.log(params);
        this.verificationCodeAsync(params);
      }
      this.codeCount();
    },
    // 发送验证码
    async verificationCodeAsync(params) {
      try {
        await verificationCode(params);
      } catch (e) {
        Message.fail(e);
      }
    },
    codeCount() {
      this.disabled = true;
      this.count -= 1;
      this.timeRef = setInterval(() => {
        this.count -= 1;
        if (this.count < 1) {
          this.disabled = false;
          this.count = 60;
        }
      }, 1000);
    },
    async onSubmit(values) {
      const { phone, verify, address = "" } = values;
      // area.split('/')
      // _address = `${address} ${area.replace(/\//g, " ")}`;
      if (!this.consent) {
        Message.fail(this.$t("register.请阅读并同意隐私政策和服务条款"));
        return;
      }
      const params = {
        phone,
        code: verify,
        address,
      };
      try {
        await feedbackAdd2(params);
        Message.success(this.$t("index.提交成功"));
      } catch (e) {
        // Message.fail(e);
        Message.fail(this.$t("index.提交失败"));
      }
      this.reset();
    },
    reset() {
      this.phone = "";
      this.verify = "";
      this.address = "";
      this.$refs.form.resetValidation();
    },
    validatorTel(value) {
      const phoneReg = /^1[3456789][0-9]{9}$/;
      if (this.isCN) return phoneReg.test(value);
      // 非中文不验证电话
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/func.scss";
img {
  vertical-align: top;
}
::v-deep .van-field {
  background-color: rgba($color: #000000, $alpha: 0);
  display: flex;
  align-items: start;
  font-size: px2rem(14px);
  // padding-top: px2rem(10px);
}
::v-deep .van-field__control {
  background-color: #fff;
  opacity: 0.5;
  height: px2rem(28px);
  border-radius: px2rem(20px);
  padding: 0 px2rem(10px);
}
::v-deep .van-field__value {
  height: px2rem(45px);
}
::v-deep .van-cell__title {
  padding-top: px2rem(3px);
  min-width: px2rem(60px);
  text-align: center;
}
::v-deep .van-button--info {
  height: px2rem(35px);
  background-color: #000;
  border: none;
  background: black;
  border-radius: 60px;
}
::v-deep .van-checkbox__label {
  font-size: px2rem(14px);
  color: #000;
  text-align: left;
  line-height: px2rem(20px);
}
.wrap {
  width: 100vw;
  position: relative;
  .submit-btn-wrap {
    margin: px2rem(10px) auto;
    width: px2rem(160px);
  }
  .back {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .box {
    position: absolute;
    top: px2rem(50px);
    width: 90vw;
    left: 50%;
    margin-left: -45vw;
    text-align: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: px2rem(60px);
    padding: px2rem(15px) 0;
    .textbox {
      width: px2rem(300px);
      margin: 0 auto;
      .text {
        border-radius: px2rem(8px);
        padding-top: px2rem(8px);
        padding-bottom: 0;
        // margin: px2rem(10px) 0;
        // line-height: px2rem(16px);
      }
    }
    .title {
      font-family: PingFang-SC-Regular;
      font-size: px2rem(26px);
      padding: px2rem(10px) 0;
    }
    .phonebox {
      display: flex;
      position: relative;
      i {
        font-size: px2rem(16px);
        padding-top: px2rem(1px);
      }
      .el-icon-plus {
        position: relative;
        right: px2rem(154px);
        top: px2rem(1px);
        color: #000;
      }
      .van-field__button {
        position: absolute;
      }
      ::v-deep .van-field__control {
        width: px2rem(130px);
      }
      ::v-deep .van-field__label {
        margin-right: px2rem(20px);
      }
    }
    .verifybox {
      // display: flex;
      .verifyinput {
        padding-top: px2rem(8px);
        padding-bottom: 0;
        // border-radius: px2rem(8px);
        // line-height: px2rem(16px);
      }
      .verifybtn {
        // width: 50%;
        background-color: black;
        border: none;
        // margin-top: px2rem(10px);
        height: px2rem(30px);
      }
    }
  }
}
</style>
