var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_vm._m(0),_c('div',{staticClass:"box"},[_c('p',{staticClass:"title"},[_vm._v("ORI "+_vm._s(_vm.$t("index.预约试驾")))]),_c('van-form',{ref:"form",staticClass:"textbox",attrs:{"label-width":"auto","colon":"","show-error":false},on:{"submit":_vm.onSubmit}},[_c('div',[_c('van-field',{staticClass:"text",attrs:{"name":"address","label":_vm.$t('index.体验地点'),"required":"","rules":[{ required: true, message: _vm.$t('index.请填写体验地点') }]},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('div',{staticClass:"phonebox"},[(!_vm.isCN)?_c('van-field',{staticClass:"text countrycode",attrs:{"type":"tel","name":"countrycode","label":_vm.$t('register.请输入国家编码'),"required":"","rules":[
            { required: true, message: _vm.$t('register.请输入国家编码') },
          ]},model:{value:(_vm.countrycode),callback:function ($$v) {_vm.countrycode=$$v},expression:"countrycode"}},[(!_vm.isCN)?_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"button"},slot:"button"}):_vm._e()]):_vm._e()],1),_c('div',[_c('van-field',{staticClass:"text",attrs:{"type":"tel","name":"phone","label":_vm.$t('index.手机号'),"required":"","rules":[
            { required: true, message: _vm.$t('index.请填写手机号') },
            { message: _vm.$t('index.格式不正确'), validator: _vm.validatorTel },
          ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"verifybox"},[_c('van-field',{staticClass:"verifyinput",attrs:{"name":"verify","label":_vm.$t('register.验证码'),"required":"","rules":[{ required: true, message: _vm.$t('register.请输入验证码') }]},model:{value:(_vm.verify),callback:function ($$v) {_vm.verify=$$v},expression:"verify"}},[_c('van-button',{staticClass:"verifybtn",attrs:{"slot":"button","block":"","type":"info","disabled":_vm.disabled},on:{"click":_vm.onCodeBtnClick},slot:"button"},[_vm._v(" "+_vm._s(_vm.disabled ? `${_vm.count}s` : _vm.$t("register.获取验证码"))+" ")])],1)],1),_c('div',[(!_vm.isCN)?_c('van-checkbox',{staticClass:"checkbox",attrs:{"name":"consent","label":_vm.$t('register.验证码'),"required":""},model:{value:(_vm.consent),callback:function ($$v) {_vm.consent=$$v},expression:"consent"}},[_vm._v(" I have read and agree to the "),_c('a',{staticStyle:{"color":"brown","text-decoration":"underline"},attrs:{"href":"/privacy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{staticStyle:{"color":"brown","text-decoration":"underline"},attrs:{"href":"/terms","target":"_blank"}},[_vm._v("Terms of service.")])]):_vm._e()],1),_c('div',{staticClass:"submit-btn-wrap"},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit","color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("index.提交"))+" ")])],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back"},[_c('img',{attrs:{"src":require("@assets/imgs/partnerM/3.png")}})])
}]

export { render, staticRenderFns }