<template>
  <div class="wrap">
    <div class="btn-wrap" @click="goto('/partner')" v-if="hidden">
      <i class="iconfont icon-tiyu-motuo motuo"></i>
      <span class="btn-text">{{ $t("index.合作伙伴申请") }}</span>
    </div>
    <!-- <Modal :title="$t('index.预约试驾')" v-model="show">
      <FloatForm ref="floatForm" @submit="closePopUp" />
    </Modal> -->
  </div>
</template>

<script>
import platform from "@utils/platform";
import { goto } from "@/utils/goto";
// import FloatForm from "./FloatForm.vue";
// import Modal from "@components/Modal.vue";
export default {
  data() {
    return {
      platform,
      hidden: "",
      // show: false,
    };
  },
  // components: { FloatForm, Modal },
  mounted() {
    const url = window.location.pathname;
    // console.log("url地址=" + url);
    if (url == "/partner" || url == "/partner/thanks"|| url == "/product/ORI" || url == "/product/Alpha") {
      this.hidden = false;
    } else {
      this.hidden = true;
    }
  },
  methods: {
    goto,
    // showPopup() {
    //   goto();
    // },
    // closePopUp() {
    //   this.show = false;
    // },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

// pc端
@media (min-width: $screen-md-min) {
  .m {
    display: none;
  }
  img {
    vertical-align: top;
  }
  .wrap {
    cursor: pointer;
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 99;
    .btn-wrap {
      background: rgba(66, 71, 65, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      height: 55px;
      border-radius: 55px;
      font-weight: bold;
      .motuo {
        color: yellow;
        font-size: 30px;
        margin-right: 5px;
      }
      .btn-text {
        color: #fff;
        font-size: 24px;
      }
    }
  }
}
// m端
@media (max-width: $screen-md-min) {
  .pc {
    display: none;
  }
  .wrap {
    position: fixed;
    bottom: px2rem(80px);
    right: px2rem(2px);
    z-index: 99;
    .btn-wrap {
      background: rgba(66, 71, 65, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: px2rem(35px);
      padding: 0 px2rem(15px);
      height: px2rem(35px);
      font-weight: bold;
      .motuo {
        color: yellow;
        font-size: px2rem(20px);
        margin-right: px2rem(5px);
      }
      .btn-text {
        color: #fff;
        font-size: px2rem(20px);
      }
    }
  }
}
</style>